// Action 提交的是 mutation，而不是直接变更状态。
// Action 可以包含任意异步操作。
/*eslint-disable*/
import { deleteLocalStorageItem } from "@/utils/localstorage/LocalstorageKit";
import commonApi from "@/api/commonApi";
const action = {
    add({
        commit
    }) {
        commit('add')
    },
    logout({
        commit,
        state
    }, payload) {
        state.user = {};
        state.isLogin = false;
        state.token = '';
        state.menuFinish = false;
        state.isCollapse = false;
        state.currentMenu = '';
        deleteLocalStorageItem('token')
        commit('RESET_STATE', null, {
            root: true
        })
        router.push({
            path: '/login'
        });
    },
    getAreaList({ commit }) {
        return new Promise((resolve, reject) => {
            function setArea(treelist) {
                for (let i = 0; i < treelist.length; i++) {
                    const element = treelist[i];
                    if(element.childNodeList.length>0){
                        setArea(element.childNodeList);
                    }else{
                        element.childNodeList=undefined;
                    }
                    
                }
            }
            commonApi.listCity({ name: "" }).then((res) => {
                let { code, data, msg } = res;
                if (code == '200') {
                    setArea(data)
                    commit('SET_AREALIST', data);
                    resolve(data);
                } else {
                    let error = new Error(msg || '用户信息查询失败')
                    error.response = res
                    error.isExpire = true
                    reject(data)
                }
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default action;