/**
 * 页面跳转
 * @param {*} msg 
 */
 export function navTo(context,path){
   context.$router.push(path)
 }

 /**
  * 带参数跳转
  * @param {*} context 
  * @param {*} path 
  * @param {*} params 
  */
 export function queryParam(context,name,params){
   context.$router.replace({name:name,params:params})
 }