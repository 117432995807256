import * as AppHttpKit from '@/utils/http/AppHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const loginApi = {
    login: (data) => AppHttpKit.postJSON(`/v1/login`,data),//这里必须写清楚接口的注释说明
    modifyPass:(data)=>AppHttpKit.postJSON(`/v1/sysUser/modifyPass`,data), // 修改密码
    userInfo:(data)=>AppHttpKit.get(`/v1/sysUser/userInfo`,data), // 查看当前用户信息
    logout: (data) => AppHttpKit.postJSON(`/v1/logout`,data),//退出登录
    sendLoginSMS:(data)=>AppHttpKit.postForm(`/v1/sendLoginSMS`,data),//发送短信验证码
    loginWithSMS:(data)=>AppHttpKit.postJSON(`/v1/loginWithSMS`,data),//发送短信验证码

}
export default loginApi;