<template>
  <div id="app">
    <!-- <main-layout/> -->
    <router-view></router-view>
  </div>
</template>

<script>
import MainLayout from "./components/layout/MainLayout.vue";
export default {
  name: "App",
  components: {
    MainLayout,
  },
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("storeState")) {
      //replaceState，替换store的根状态
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("storeState"))
        )
      );
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("storeState", JSON.stringify(this.$store.state));
    });
  },

  methods: {},
};
</script>
<style scoped>
#app {
  width: 100%;
  height: 100%;
}
</style>
<style>
.required::before,
.required .el-form-item__label::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
</style>