/* eslint-disable */
/**
 * 【温馨提示】，假如不想背锅，没事别动这个文件里面的任何东西，会导致整个项目网络请求崩溃,想好在动
 */
import axios from 'axios'; // 引入axios
import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到
import { getLocalStorageItem, deleteLocalStorageItem } from '@/utils/localstorage/LocalstorageKit'
import { gernerateStrV1, decryptJson } from '@/utils/SpiderHack.js'
import Vue from 'vue'
import store from '@/store';
const app = axios.create({
  timeout: 10000,
  baseURL: process.env.NODE_ENV !== "development" ? process.env.VUE_APP_API : ''
});
//HttpRequest拦截器
app.interceptors.request.use(
  async (config) => {
    // 获取token
    let result = await new Promise((resolve, reject) => {
      let token = (getLocalStorageItem("token") ? getLocalStorageItem("token") : "");
      config.headers.authorization = token ? `Bearer ${token}` : '';
      config.headers.platform = 'pc';
      resolve(config);
    });
    let sign = gernerateStrV1()
    config.headers.sign = sign
    return result;
  },
  (err) => {
    return Promise.reject(err);
  }
);


//HttpResponse拦截器
app.interceptors.response.use(
  (response) => {
    if (response.headers.isencryption && response.headers.isencryption == 1 && response.data.payload) {
      let param = ['data', 'result', 'records']
      let current = param.find(i => i in response.data)
      if (current) {
        let decryptData = decryptJson(response.data.payload)
        let dataType = response.headers.datatype
        let handler = { 'object': JSON.parse, 'list': JSON.parse, 'long': parseFloat, 'integer': parseFloat, 'boolean': (parse) => typeof (parse) == 'boolean' ? parse : typeof (parse) == 'string' && parse == 'true' ? true : false }
        response.data[current] = handler[dataType] ? handler[dataType](decryptData) : decryptData;
      }
    }
    if (response.data.state === 'FAIL' && response.data.code && response.data.code[0] == '4') {
      Vue.prototype.$alert(response.data.msg, '提示', {
        confirmButtonText: '重新登录',
        showClose: false,
        type: 'warning'
      }).then((resp) => {
        //清除当前token信息和登录信息
        deleteLocalStorageItem("token");
        deleteLocalStorageItem("userInfo");
        store.commit("removeTags");
        store.commit("SET_ISLOGIN", false)
        window.location.href = "/login"
      }).catch(error);
    }
    return response;
  },
  (error) => {
    return Promise.reject(error)
  }
);


/**
 * GET请求方式
 * @param {*} url
 * @param {*} params
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    app.get(url, {
      params: params
    })
      .then(response => {
        if (response.data.code === '200') {
          //返回成功处理  这里传的啥 后续调用的时候 res就是啥
          resolve(response.data);//我们后台所有数据都是放在返回的data里所以这里统一处理了
        } else {
          //错误处理
          resolve(response.data);
        }
      })
      .catch(err => {
        reject(err);
        let message = '请求失败！请检查网络';
        //错误返回
        if (err.response) {
          message = err.response.data.message;
        }
      })
  })
}

/**提交JSON数据
 * @param url
 * @param data
 * @returns {Promise}
 */

export function postJSON(url, data = {}) {
  return new Promise((resolve, reject) => {
    app.post(url, data)
      .then(response => {
        if (response.data.code === '200') {
          resolve(response.data);
        } else {
          resolve(response.data);
        }
      }, err => {
        reject(err);
        let message = '请求失败！请检查网络';
        if (err.response) {
          message = err.response.data.message;
        }
      })
  })
}

/**
 * 提交表单数据
 * @param {*} url
 * @param {*} data
 */
export function postForm(url, data = {}) {
  return new Promise((resolve, reject) => {
    app({
      method: 'post',
      url: url,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, // 请求头，发送FormData格式的数据，必须是 这种请求头。
      data: QS.stringify(data)
    }).then(response => {
      if (response.data.code === '200') {
        resolve(response.data);
      } else {
        resolve(response.data);
      }
    }, err => {
      reject(err);
      let message = '请求失败！请检查网络';
      if (err.response) {
        message = err.response.data.message;
      }
    })
  })
}

export function upload(e, url) {
  let file = e.target.files[0];
  let param = new FormData(); //创建form对象
  param.append('file', file, file.name);//通过append向form对象添加数据
  param.append('chunk', '0');//添加form表单中其他数据
  return new Promise((resolve, reject) => {
    app({
      method: 'post',
      url: url,
      headers: { 'Content-Type': 'multipart/form-data' }, // 请求头，发送FormData格式的数据，必须是 这种请求头。
      data: param
    }).then(response => {
      if (response.data.code === '200') {
        resolve(response.data);
      } else {
        resolve(response.data);
      }
    }, err => {
      reject(err);
      let message = '请求失败！请检查网络';
      if (err.response) {
        message = err.response.data.message;
      }
    })
  })
}



