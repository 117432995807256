<template>
  <el-container>
    <el-header class="header top-bar">
      <div class="nav-container">
        <div class="nav-container-left">
          <div class="logo" @click="goto('/home')">
            <img src="../../assets/home/logoTitle.png" />
            <!-- <img src="../../assets/home/logo1.png" /> -->
          </div>
          <div class="postios">
            <i class="el-icon-arrow-left left" v-show="showControllerParent" @click="scrollParent('left')"></i>
          </div>
          <div class="menu" ref="menu">
            <div class="box">
              <div class="menu-content" :style="styles" ref="btns">
                <div class="menu-box" @click="goto('/home')">
                  <el-button type="text" class="nav-btn background">工作台</el-button>
                  <div class="menu-line" />
                </div>

                <div ref="menubox" @mouseleave="leveout(index)" @click="toworkspaces(item)"
                  v-debounce="[() => levein(index), 100]" :class="
                    showactive == index || currentParentIndex == index
                      ? 'menu-box-active'
                      : ''
                  " class="menu-box" v-for="(item, index) in menuList" :key="index">
                  <el-button v-if="item.meta" type="text" class="nav-btn background"
                    :class="currentParentIndex == index ? 'btn-active' : ''" v-show="item.meta.isShow">{{
                        item.meta.Title
                    }}</el-button>
                  <div class="menu-line" />
                </div>
                <div class="menu-box" @click="goto('/upgrade')" v-if="isAdmin">
                  <el-button type="text" class="nav-btn background">移动端应用更新管理</el-button>
                  <div class="menu-line" />
                </div>
              </div>
            </div>
            <div v-for="(itemChild, indexChild) in menuList" :key="indexChild" class="menu-children" ref="menuchildren"
              v-show="getMenuListShow(itemChild)" :class="showactive == indexChild ? 'menu-active' : ''"
              @mouseleave="leveout(indexChild)" @click.self="goto(itemChild)"
              v-debounce="[() => leveinchildren(indexChild), 300]">
              <div v-for="(item1, index1) in itemChild.children" @click.self="goto(item1)" :key="index1"
                class="menu-item" v-if="getShow(item1)">
                {{ getname(item1) }}
              </div>
            </div>
          </div>
          <div class="postios">
            <i class="el-icon-arrow-right right" v-show="showControllerParent" @click="scrollParent('right')"></i>
          </div>
        </div>
        <div class="nav-container-right">
          <div class="submenuClass">
            <el-menu mode="horizontal" background-color="#fff" text-color="#333" active-text-color="#333">
              <el-submenu index="11">
                <template slot="title">
                  <el-avatar style="" :size="30" :src="$store.state.user.img || circleUrl"></el-avatar>
                  {{
                      $store.state.user.realName || $store.state.user.nickName
                  }}
                </template>
                <!-- <el-menu-item  index="11-0" >
                  <i class="el-icon-edit" style="color: white"></i>{{$store.state.user.roleName}}
                </el-menu-item>  -->
                <!-- <el-menu-item index="11-1" v-if="$store.state.user.companyName">
                  <i class="el-icon-edit" style="color: white"></i
                  >{{ $store.state.user.companyName }}
                </el-menu-item> -->
                <!-- <el-menu-item
                  index="11-2"
                  @click="goto({ name: '修改密码', url: '/modifyUserPass' })"
                > 
                  <i class="el-icon-edit" style="color: white"></i>修改密码
                </el-menu-item>-->
                <el-menu-item index="11-3" @click="exit"><i class="el-icon-s-promotion" style="color: white"></i>退出系统
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
        </div>
      </div>
    </el-header>
     <div class="tagsbox">
          <div class="taglines">
            <el-button v-show="showController" @click="scroll('left')" icon="el-icon-arrow-left"
              style="margin-left: 5px" class="btns" size="mini"></el-button>
            <div class="tagcontent" ref="tagcontent">
              <div class="tagbox" :style="stylestags" ref="tags">
                <el-tag closable size="medium" v-for="(tag, index) in tags" :key="index" :disable-transitions="true"
                  v-show="tag.name != ''" :effect="tag.path == $route.path ? 'dark' : 'plain'"
                  @close="handleClose(tag, index)" @click="handleClick(tag)" style="margin-bottom: 10px">
                  {{ getname(tag) }}
                </el-tag>
              </div>
            </div>
            <el-button v-show="showController" @click="scroll('right')" icon="el-icon-arrow-right" class="btns"
              size="mini"></el-button>
          </div>
          <div class="tagclose">
            <el-dropdown @command="commandClose">
              <el-button type="primary" size="mini" class="closeTag">
                标签选项
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="other" class="dropdown-item">关闭其他</el-dropdown-item>
                <el-dropdown-item command="all" class="dropdown-item">关闭所有</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
    <el-container class="main-container">
      <el-main class="container" style="padding: 0px">       
        <div class="section">
          <keep-alive>
            <router-view v-show="!isLoading" v-if="$route.meta.isCache"></router-view>
          </keep-alive>
          <router-view v-show="!isLoading" v-if="!$route.meta.isCache"></router-view>
        </div>
        <el-backtop target=".container"></el-backtop>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import loginApi from "@/api/LoginApi";
import { deleteLocalStorageItem } from "@/utils/localstorage/LocalstorageKit";
import basePage from "../../pages/base/basePage.vue";
import BaseRouterView from "./BaseRouterView";
const img = require("../../assets/home/tx.png");
import { mapState, mapMutations } from "vuex";
export default {
  name: "MainLayout",
  extends:basePage,
  data() {
    return {
      activeIndex: "0",
      isLoading: true,
      circleUrl: img,
      menuList: [],
      tags: this.$store.state.stateTagsList,
      showactive: -1,
      styles: { left: 0 },
      currentPath: this.$store.state.current_tag,
      currentParentIndex: -1,
      showController: false,
      stylestags: {
        left: 0,
      },
      showControllerParent: false,
    };
  },
  components: {
    BaseRouterView,
  },
  beforeUpdate(to, from, next) { },
  created() {
    this.menuList = this.$router.options.routes;

    setTimeout(() => {
      this.isLoading = false;
    }, 10);
  },
  computed: {
    tagsData() {
      return this.$store.state.stateTagsList;
    },
    currentTagData() {
      return this.$store.state.current_tag;
    },
  },
  methods: {
    ...mapMutations({
      close: "mutationCloseTag",
      removeTageList: "removeTageList",
      setIsLogin: "SET_ISLOGIN",
    }),
    handleClose(tag, index) {
      if (this.tags.length === 1) {
        this.$router.push({ path: "/home" });
        this.close(tag); // 删除当前tag
        return;
      }
      this.close(tag); // 删除当前tag
      if (this.$router.path === tag.path) {
        // 如果关闭的标签不是当前路由的话，不做路由跳转
        return;
      } else {
        if (index === this.tags.length - 1) {
          // 关闭最后一个标签,则路由跳转至最后一个
          this.$router.push(this.tags[index].path);
        } else {
          // 路由跳转至下一个标签页
          if (index === 0) {
            this.$router.push(this.tags[0].path);
          } else {
            this.$router.push(this.tags[index - 1].path);
          }
        }
      }
    },
    handleClick(tag) {
      // console.log(tag);
      this.goto(tag);
    },
    goto(path) {
      let obj = {};
      if (path == "/home") {
        obj = { name: "home", url: "/home", path: "/home" };
      } else if (path == "/upgrade") {
        obj = { name: "upgrade", url: "/upgrade", path: "/upgrade" };
      } else {
        obj = path;
      }
      if (obj.path != "/") {
        this.navTo(obj.path || obj.url);
      }
    },
    exit() {
      this.$confirmDialog("确定要进行当前操作?", (resp) => {
        loginApi.logout().then((res) => {
          if (res.code === "200") {
            this.$store.commit("removeTags");
            this.setIsLogin(false);
            deleteLocalStorageItem("token");
            deleteLocalStorageItem("userInfo");
            window.location.href = "/login";
          }
        });
      });
    },
    leveout(index) {
      this.showactive = -1;
    },
    toworkspaces(item) {
      // console.log(item)
      if (item && item.children && item.children.length > 0) {
        this.goto(item);
      }
    },
    levein(index) {
      this.showactive = index;
      var menuleft = this.$refs.btns.offsetLeft;
      var left = this.$refs.menubox[index].offsetLeft;
      if (menuleft < 0) {
        this.$refs.menuchildren[index].style.left = left + menuleft + "px";
      } else {
        this.$refs.menuchildren[index].style.left = left + "px";
      }
    },
    leveinchildren(index) {
      this.showactive = index;
    },
    currentPathParent() {
      var obj = this.recursion(this.menuList, this.currentPath);
      if (obj == null) return;
      for (var i = 0; i < this.menuList.length; i++) {
        if (this.menuList[i].name == obj.name) {
          this.currentParentIndex = i;
          return;
        }
      }
    },
    //滚动条显示隐藏
    initTag() {
      const TagWidth = this.$refs.tags.offsetWidth;
      const TagContentWidth = this.$refs.tagcontent.offsetWidth;
      if (TagWidth > TagContentWidth) {
        this.showController = true;
      } else {
        this.showController = false;
      }
    },
    //tags左右滚动
    scroll(position) {
      const TagWidth = this.$refs.tags.offsetWidth;
      const TagLeft = this.$refs.tags.offsetLeft;
      const TagContentWidth = this.$refs.tagcontent.offsetWidth;
      if (position == "left") {
        if (TagLeft >= 0) {
          return false;
        } else {
          this.stylestags.left = TagLeft + 70 >= 0 ? 0 : TagLeft + 70 + "px";
        }
      } else {
        if (TagLeft >= TagWidth - TagContentWidth) {
          return false;
        } else {
          this.stylestags.left =
            TagLeft - 70 <= -1 * (TagWidth - TagContentWidth)
              ? -1 * (TagWidth - TagContentWidth) + "px"
              : TagLeft - 70 + "px";
        }
      }
    },
    commandClose(command) {
      if (this.tags.length < 1) return;
      if (command == "other") {
        const taglist = [];
        const currentpath = this.$route.path;
        this.tags.forEach((p) => {
          if (p.meta != undefined) {
            if (p.path != currentpath) {
              taglist.push(p);
            }
          }
        });
        this.removeTageList(taglist);
      } else {
        this.removeTageList(this.tags);
        this.goto("/home");
      }
      this.showController = false;
    },
    //header< >显示
    initShow() {
      const TagContentWidth = this.$refs.menu.offsetWidth;
      const TagWidth = this.$refs.btns.offsetWidth;
      if (TagWidth > TagContentWidth) {
        this.showControllerParent = true;
      } else {
        this.showControllerParent = false;
        this.styles.left = 0;
      }
    },
    //header左右滚动
    scrollParent(position) {
      const TagWidth = this.$refs.btns.offsetWidth;
      const TagLeft = this.$refs.btns.offsetLeft;
      const TagContentWidth = this.$refs.menu.offsetWidth;
      if (position === "left") {
        if (TagLeft >= 0) {
          return false;
        } else {
          this.styles.left = TagLeft + 86 >= 0 ? 0 : TagLeft + 86 + "px";
        }
      } else {
        if (TagLeft >= TagWidth - TagContentWidth) {
          return false;
        } else {
          this.styles.left =
            TagLeft - 86 <= -1 * (TagWidth - TagContentWidth)
              ? -1 * (TagWidth - TagContentWidth) + "px"
              : TagLeft - 86 + "px";
        }
      }
    },
    deepQuery(objList, to) {
      let result = null;
      if (objList != undefined) {
        for (let i = 0; i < objList.length; i++) {
          const element = objList[i];
          if (element.name == to.name) {
            result = element;
            break;
          } else {
            let res = this.deepQuery(element.children, to);
            if (res != null) result = res;
          }
        }
      }
      return result;
    },
    recursion(objList, to) {
      let result = null;
      for (let i = 0; i < objList.length; i++) {
        const element = objList[i];
        if (element.name == to.name) {
          result = element;
          break;
        } else {
          if (element.children == undefined || element.children.length == 0)
            continue;
          let res = this.recursion(element.children, to);
          if (res != null) {
            result = element;
            break;
          }
        }
      }

      return result;
    },
    getShow(item) {
      if (typeof item.meta != "undefined") {
        return item.meta.isShow;
      }
    },
    getname(item) {
      if (typeof item.meta != "undefined") {
        return item.meta.Title;
      }
    },
    trim(str) {
      str = str.replace(/^(\s|\u00A0)+/, "");
      for (var i = str.length - 1; i >= 0; i--) {
        if (/\S/.test(str.charAt(i))) {
          str = str.substring(0, i + 1);
          break;
        }
      }
      return str;
    },
    getMenuListShow(itemChild) {
      if (itemChild.meta && itemChild.children.length > 0) {
        let lst = itemChild.children.filter((p) => {
          return p.meta.isShow;
        });
        return lst.length > 0;
      } else {
        return false;
      }
    },
    setUserInfo() {
      loginApi.userInfo().then((res) => {
        if (res.code == "200") {
          this.$store.commit("SET_USER", res.data);
        }
      });
    },
  },

  watch: {
    tagsData(newVal, Old) {
      this.tags = newVal;
      this.$nextTick(this.initTag(), 100);
    },
    currentTagData(newVal, Old) {
      this.currentPath = newVal;
      this.currentPathParent();
    },
  },
  mounted() {
    this.initShow();
  },
};
</script>

<style scoped lang="scss">
.header {
  background-color: white;
  display: inline-flex;
  color: #333;
  width: 100%;
  height: 56px !important;
  box-shadow: 0px 1px 4px 1px rgba(115, 119, 126, 0.26);
}

.logo {
  display: flex;
  justify-content: baseline;
  height: 32px;
  margin: 12px 16px 12px 0;
}

// .logo img:first-child {
//   width: 32px;
//   height: 32px;
// }
.logo img:last-child {
  width: 135px;
  height: 32px;
  // margin: 5px 8px;
}

.footer {
  text-align: center;
  color: #c0c4cc;
  font-size: 14px;
}

.main-container {
  height: calc(100vh - 90px);
}

.container {
  width: 100%;
}

.section {
  padding: 20px;
}

.nav-container {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;

  .postios {
    line-height: 56px;
    height: 56px;
    padding: 0 10px;

    >i {
      display: inline-block;
      cursor: pointer;
    }

    .left {
      left: 14px;
    }

    .right {
      right: 14px;
    }
  }
}

.nav-container-left {
  display: inline-flex;
}

.nav-container-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-bar {
  display: flex;
  height: 56px;
  line-height: 56px;
  color: #333;
  z-index: 10;

  .postios {
    line-height: 56px;
    height: 56px;
    padding: 0 10px;

    >i {
      display: inline-block;
      cursor: pointer;
    }

    .left {
      left: 14px;
    }

    .right {
      right: 14px;
    }
  }
}

.el-tag {
  margin-left: 10px;
  cursor: pointer;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.el-tag--medium {
  height: 24px;
  line-height: 24px;
}

.el-tag--plain {
  border-color: #ebebeb;
  color: #999999;
}

.el-tag--dark {
  background-color: #2c6aff;
  border-color: #2c6aff;
  color: #fff;
}

::v-deep .el-tag--dark>.el-tag__close.el-icon-close {
  color: #fff;
}

::v-deep .el-tag__close.el-icon-close {
  color: #999999;
}

::v-deep .el-tag__close.el-icon-close:hover {
  background-color: #f56c6c;
}

.menu {
  flex: 1;
  position: relative;
  white-space: nowrap;
  display: flex;
  max-width: calc(100vw - 450px);

  .menu-children {
    position: absolute;
    width: 290px;
    height: 0;
    transition: height 0.3s ease-in;
    background: #ffffff !important;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.06) !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    top: 56px;
    left: 0;
    z-index: 9999;
    color: #000;
    overflow: hidden;

    .menu-item {
      float: left !important;
      background: #ffffff;
      color: #000;
      width: 32%;
      text-align: center;
      margin-left: 5px !important;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 25px;
      height: 25px;
      margin-top: 16px;
      cursor: pointer;
    }

    .menu-item:nth-child(3n) {
      margin-left: 0 !important;
    }

    .menu-item:last-child {
      margin-bottom: 16px;
    }

    .menu-item:hover {
      color: #2c6aff;
      border-radius: 4px;
    }
  }

  .menu-active {
    height: auto;
    // border: 1px solid #4bb019;
    background: #ffffff;
    box-shadow: 0px 1px 4px 1px rgba(115, 119, 126, 0.26);
    border-top: 0px;
  }

  ::v-deep .el-submenu__icon-arrow {
    display: none !important;
  }

  .box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    position: relative;
  }

  .menu-content {
    height: 100%;
    align-items: center;
    left: 0;
    top: 0;
    transition: left 0.3s ease-out;
    display: flex;
    position: relative;

    ul {
      display: flex;
    }

    .nav-btn {
      font-size: 16px;
      height: 34px;
      color: #333;
      padding: 0 10px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.2) !important;
        color: #2c6aff;
      }
    }

    .menu-box {
      padding: 0 5px;
    }
  }
}

.btn-active {
  background: rgba(255, 255, 255, 0.2) !important;
  color: #fff;
}

.tagsbox {
  display: flex;
  height: 32px;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid rgba(154, 154, 154, 0.4);
  z-index: 9;
  moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: white;

  .el-button--mini,
  .el-button--mini.is-round {
    padding: 5px 8px;
  }

  .taglines {
    display: flex;
    flex: 1;
    bottom: 0;
    height: 32px;
    margin-right: 3px;

    .btns {
      width: 24px;
      height: 24px;
      justify-content: center;
      display: flex;
      margin-top: 4px;
      border: solid 1px rgba(44, 106, 255, 0.9);
    }

    .btns:hover {
      background: #2c6aff;
      border: solid 1px #2c6aff;
    }

    .tagcontent {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      position: relative;

      .tagbox {
        position: absolute;
        padding-top: 4px;
        top: 0;
        bottom: 0;
        transition: left 0.3s ease-out;

        .el-tag {
          margin-left: 4px;
          cursor: pointer;
        }

        .el-tag:first-child {
          margin-left: 13px;
        }

        .active {
          color: #fff;
        }
      }
    }
  }

  .tagclose {
    width: 90px;
    line-height: 28px;
  }
}

.closeTag {
  background: #2c6aff;
}

.closeTag:hover {
  background: rgba(44, 106, 255, 0.9);
}

.dropdown-item {
  color: #2c6aff;
}

.dropdown-item:hover {
  background: rgba(44, 106, 255, 0.9);
}

::v-deep .el-dropdown-menu__item:hover {
  background: rgba(44, 106, 255, 0.9) !important;
  color: #fff !important;
}

.companyClass {
  font-size: 16px;
  max-width: 200px;
  max-height: 55px;
  word-break: break-word;
  color: #fff;
}

::v-deep .el-header {
  padding: 0 0 0 20px;
}

.submenuClass {
  width: 180px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  /deep/.el-submenu__title {
    border-bottom-color: #fff !important;
  }
}

::v-deep .el-menu--horizontal {
  border-bottom: 0 !important;
  height: 56px;
  display: flex;
  justify-content: right;
}

::v-deep .el-submenu__title {
  height: 56px;
  overflow: hidden;
}

::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title {
  height: 56px;
}

::v-deep .el-button--success {
  border-color: rgba(44, 106, 255, 0.9);
}

.menu-line {
  width: 0%;
  margin: 0 auto;
  background: #2c6aff;
  height: 5px;
  border-radius: 2px;
  transition: width 0.3s ease-in;
}

.menu-box:hover .menu-line,
.menu-box-active .menu-line {
  width: 100%;
}
</style>
<style >
.el-menu.el-menu--horizontal {
  border-bottom: 0 !important;
}

.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 175px !important;
}

.popper__arrow {
  top: 0 !important;
  display: none !important;
  margin-top: 0 !important;
}
::-webkit-scrollbar {
    width: 9px;
    height: 9px;
}
::-webkit-scrollbar-thumb {
    background-color: #e1e1e1;
    border-radius: 2em;
}
::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 2em;
}
</style>