<script>
import { navTo, queryParam } from "@/utils/NavigatorUtils";
export default {
    data() {
        return {
            //超级管理员
            isAdmin: false,
            //运营公司管理员
            isPlatform: false,
            //运营公司员工
            isCompany: false,
            //车队
            isMotorcade: false,
            //客服用户
            isCustomer: false,
            //派出所用户
            isTraffic_bureau: false,
            //是否存在企业no
            existsCompanyNo: false,
        }
    },
    created() {
        let type = this.$store.state.user.type;
        this.existsCompanyNo = this.$store.state.user.companyNo != null && this.$store.state.user.companyNo != "";
        switch (type) {
            case 'admin': this.isAdmin = true; break;
            case 'platform': this.isPlatform = true; break;
            case 'company': this.isCompany = true; break;
            case 'motorcade': this.isMotorcade = true; break;
            case 'customer': this.isCustomer = true; break;
            case 'traffic_bureau': this.isTraffic_bureau = true; break;
        }
    },
    methods: {
        navTo(path) {
            navTo(this, path)
        },
        queryParam(name, params) {
            queryParam(this, name, params);
        },
        dateFormatType(val) {
            if (!val) {
                return "";
            }
            var date = new Date(val);
            var year = date.getFullYear();
            var month = date.getMonth() + 1; // js从0开始取
            var date1 = date.getDate();
            var hour = date.getHours();
            var minutes = date.getMinutes();
            var second = date.getSeconds();
            if ((month + "").length === 1) {
                month = "0" + month;
            }
            if ((date1 + "").length === 1) {
                date1 = "0" + date1;
            }
            if ((hour + "").length === 1) {
                hour = "0" + hour;
            }
            if ((minutes + "").length === 1) {
                minutes = "0" + minutes;
            }
            if ((second + "").length === 1) {
                second = "0" + second;
            }

            return year + "-" + month + "-" + date1;
        },
    }
}
</script>