import BaseRoutes from './index'
export const initMenu = async (router, menu, callback) => {
    let _BaseRoutes_ = BaseRoutes
    if (menu.length === 0) {
        router.options.routes = _BaseRoutes_;
        callback()
        return
    }
    let menus = formatRoutes(menu);
    _BaseRoutes_ = _BaseRoutes_.concat(menus)
    router.addRoutes(menus);
    router.options.routes = _BaseRoutes_;
    callback()
}
export function formatRoutes(aMenu) {
    const aRouter = []
    aMenu.forEach(oMenu => {
        const {
            url,
            component,
            name,
            ico,
            identifier,
            isShow,
            isCache,
            childrenList,
            funlevel,
        } = oMenu
        if (component) {
            let nChildren = childrenList || []
            let pageChildren = nChildren.filter(p1 => p1.childrenList)
            const oRouter = {
                path: url,
                component: () => component === 'BaseRouter' && funlevel == 1 ? import('@/components/layout/MainLayout') : component === 'BaseRouter' ? import('@/components/layout/BaseRouterView.vue') : import(`@/pages/${component}`),
                meta: {
                    isShow: isShow,
                    Title: name,
                    isCache: isCache,
                    component: component
                },
                name: identifier,
                icon: 'el-icon-s-platform',
                children: formatRoutes(pageChildren)
            }
            aRouter.push(oRouter)
        }

    })
    return aRouter
}