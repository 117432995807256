function getlocalstorage(){
    if(!window.localStorage){
        alert("浏览器支持localstorage");
        return;
    }else{
        var storage=window.localStorage;
        return storage;
    }
}

/**
 * 设置本地存储数据信息
 * @param {*} key 
 * @param {*} value 
 */
export function setLocalStorageItem(key,value){
    let storage = getlocalstorage();
    storage.setItem(key,value);
}

/**
 * 读取本地存储数据信息
 * @param {*} key 
 * @param {*} value 
 */
export function getLocalStorageItem(key){
    let storage = getlocalstorage();
    return storage.getItem(key);
}

/**
 * 删除本地存储数据信息
 * @param {*} key 
 * @param {*} value 
 */
export function deleteLocalStorageItem(key){
    let storage = getlocalstorage();
    storage.removeItem(key);
}