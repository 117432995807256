const child = [
  {
    path: '/home',
    name: 'home',
    component:()=> import('@/pages/home/WelcomePage')
  },
  {
    path: '/modifyUserPass',
    name: 'modifyUserPass',
    meta:{
      Title:'修改密码'
    },
    component:()=> import('@/pages/modules/system/user/ModifyUserPassPage')
  },
  {
    path: '/upgrade',
    name: 'upgrade',
    meta:{
      Title:'升级'
    },
    component:()=> import('@/pages/modules/upgrade/upgradePage')
  },
]

const routers = [
  {
    path: '/',
    component:()=> import('@/pages/home/HomePage'),
    redirect: "/home",
    children:child
  },
  {
    path: '/login',
    component:()=> import('@/pages/home/LoginPage'),
  }
]
export default routers