import cryptoJs from 'crypto-js'
//MD5的字符串加密函数，各端请自行实现
function md5Encode(value){
  let saltVal="7%NAdOte&&6*5g!K3ZFzOgiWJ3mL0kol";
  return cryptoJs.MD5(value+"|"+saltVal).toString();
}

//拼接字符串函数
export function gernerateStrV1(){
  let nonceStr = md5Encode(randomStr(32));
  let timeStamp = new Date().getTime();
  let clientSign = md5Encode(timeStamp+nonceStr);
  let header = timeStamp+"&"+nonceStr+"&"+clientSign;
  console.log("timeStamp->"+timeStamp)
  console.log("nonceStr->"+nonceStr)
  console.log("clientSign->"+clientSign)
  return header;
}

//随机字符串函数，只要随机即可没有任何要求
export function randomStr(length){
  var str = "",
    range = length,
    arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  for(var i=0; i<range; i++){
    let pos = Math.round(Math.random() * (arr.length-1));
    str += arr[pos];
  }
  return str;
}




function Encrypt(word,key,iv){
  let srcs = cryptoJs.enc.Utf8.parse(word);
  var encrypted = cryptoJs.AES.encrypt(srcs, key, { iv: iv,mode:cryptoJs.mode.CBC,padding: cryptoJs.pad.Pkcs7});
  return encrypted.ciphertext.toString().toUpperCase();
}

function Decrypt(word,key,iv){
  var encryptedHexStr = cryptoJs.enc.Hex.parse(word);
  var srcs = cryptoJs.enc.Base64.stringify(encryptedHexStr);
  var decrypt = cryptoJs.AES.decrypt(srcs, key, { iv: iv,mode:cryptoJs.mode.CBC,padding: cryptoJs.pad.Pkcs7});
  var decryptedStr = decrypt.toString(cryptoJs.enc.Utf8);
  return decryptedStr.toString();
}



//解密函数，先定好，后期会有用
export function decryptJson(json){
  var key = cryptoJs.enc.Utf8.parse("8985b2c4fb1900cf");
  var iv  = cryptoJs.enc.Utf8.parse('bc3f07fbe54c4b90');
  var result = Decrypt(json,key,iv)
  return result;
}