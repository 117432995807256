import Vue from 'vue'
/**
 * 确认对话框
 */

export function confirmDialog(tips,success,error){
    if(!tips){
        tips="确认进行当前操作吗?";
    }

    if(!error){
        error = ()=>{
        }
    }
    Vue.prototype.$confirm(tips, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(success).catch(error);
}

/**
 * 错误提示条
 * @param {*} msg 
 */
export function errorMsg(msg){
    Vue.prototype.$message.error(msg);
}

/**
 * 成功提示条
 * @param {*} msg 
 */
 export function successMsg(msg){
    Vue.prototype.$message({
        message: msg,
        type: 'success'
      });
}