/**
 * @module vuex/state
 */
export default {
    shoWdialogVisible: false,
    num: 0,
    user: {}, // 用户信息
    stateTagsList:[],
     isLogin: false, // 登录状态
    // token: '', // token
    // menuFinish: false, // 权限菜单获取状态
    // isCollapse: false, // 菜单是否折叠
    // authDomList: [], // 允许显示的元素identifier集合
    // pending: [], //声明一个数组用于存储每个请求的取消函数和axios标识
    // tags: [], // 标签
    // currentMenu: '', //当前选中一级菜单
    current_tag: '', // 当前路由信息
    areaList:[],
}
